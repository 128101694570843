import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Message",
  "type": "Content"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Message`}</strong>{` component used to render a small message which is outside of the main content.
The `}<strong parentName="p">{`Message`}</strong>{` component should be used in a block of text ( best used in a `}<strong parentName="p">{`Card`}</strong>{` )
for text or excerpt that is relevant, however not as important as the main block of
text.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Message`}</strong>{`'s content should be always wrapped in a
`}<a parentName="p" {...{
          "href": "/typography/paragraph"
        }}><strong parentName="a">{`Paragraph`}</strong></a>{`.`}</p>
    </blockquote>
    <hr></hr>
    <h2>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true",
        "live": "true"
      }}>{`<>
  <Paragraph>
    This is a paragraph
  </Paragraph>
  <Separator />
  <Message icon={<Icons.BiInfoCircle/>}>
    <Paragraph>
      This is a message that needs to be displayed, alongside another text block
    </Paragraph>
  </Message>
</>
`}</code></pre>
    <h2>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`icon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The icon that will be rendered on the left`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The content - always with `}<strong parentName="td">{`Paragraph`}</strong></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      